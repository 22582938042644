import _ from 'lodash';
import axios from 'axios';
import { getCategories, getExpenses } from '@app/src/actions/dashboardActions';
import { setBusinessCode, setCurrentAnswer, setAddMoreItems } from '@app/src/actions/taxFlowActions';
import { getCampaign, getFeatures } from '@app/src/api/profileApi';
import { getCarPills, getHomeAddressPreselectOptions, getHomePills, updateTaxData } from '@app/src/api/taxDataApi';
import {
  CATEGORY_TYPE_ADD_MORE,
  CATEGORY_TYPE_SUMMARY,
  CATEGORY_TYPE_TAXFLOW_FORM
} from '@app/src/constants/constants';
import { serverUrl } from '@app/src/global/Environment';
import {
  navigationDoneQueriesSelector,
  navigationQueriesSelector,
  navigationStartedQueriesSelector
} from '@app/src/selectors/navigationListSelectors';
import { getBankLinks, requireAccountDetails } from '@app/src/services/pricingService';
import { getCollectionTypeItems } from '@app/src/services/taxFlowContentfulService';
import { getWorkInfoLazy } from '@app/src/services/workService';
import { setCurrentCollectionId, setCurrentTaxState } from '@app/src/taxflow/collection/actions/collectionActions';
import { requireWork } from '@app/src/taxflow/common/services/userInfo';
import {
  allCollectionTypesSelector,
  bulkUploadItemsSelector,
  flattenedUploadAttemptsSelector
} from '@app/src/taxflow/main/selectors/formUploadSelectors';
import {
  allDerivedQuestionsSelector,
  allQuestionsSelector,
  savedDefaultAnswerSelector
} from '@app/src/taxflow/main/selectors/mainSelectors';
import {
  getDoneUpdates,
  getJobData,
  getQuestionById,
  getQuestionQueries,
  getQuestionQueryResults,
  getQuestionUpdates,
  getStartedUpdates
} from '@app/src/taxflow/main/utils/mainUtils';
import { deserializeQuestionAnswer } from '@app/src/taxflow/mapping/utils/mappingUtils';
import { CAR_ENDPOINT_ATTRIBUTES, CAR_SLUGS, COLLECTION_TYPE__CAR } from '@app/src/taxflow/sections/car/carConstants';
import {
  SLUG__CREDIT_CHARITY_AMOUNT,
  SLUG__CREDIT_COGS_END_INVENTORY,
  SLUG__CREDIT_COGS_NEW_INVENTORY,
  SLUG__CREDIT_COGS_START_INVENTORY,
  SLUG__CREDIT_HOMEOWNER_DETAIL,
  SLUG__CREDIT_STANDARD_DEDUCTION,
  SLUG__CREDIT_STANDARD_EXIT_BOUNCE,
  SLUG__CREDIT_STANDARD_ITEMIZED_SUCCESS,
  SLUG__CREDIT_STANDARD_RESULT
} from '@app/src/taxflow/sections/credit/constants/creditConstants';
import {
  COLLECTION_TYPE__HOME,
  HOME_ENDPOINT_ATTRIBUTES,
  HOME_SLUGS
} from '@app/src/taxflow/sections/home/homeConstants';
import {
  COLLECTION_TYPE__INCOME_FREELANCE,
  COLLECTION_TYPE__INCOME_INVEST,
  ENDPOINT_ATTRIBUTE__INCOME_INVEST_STARTED,
  SLUG__INCOME_FREELANCE_BUSINESS_CODE,
  SLUG__INCOME_FREELANCE_JOB,
  SLUG__INCOME_INVEST_INFO,
  SLUG__INCOME_INVEST_UNIFICATION
} from '@app/src/taxflow/sections/income/constants/incomeConstants';
import { SLUG__FIND_WRITE_OFFS } from '@app/src/taxflow/sections/special/constants/specialConstants';
import { SLUG__STATE_RETURN } from '@app/src/taxflow/sections/state/constants/stateConstants';
import {
  SLUG__SUBMIT_CONFIRMATION,
  SLUG__SUBMIT_DEBIT
} from '@app/src/taxflow/sections/submit/constants/submitConstants';
import {
  setCogsSummary,
  setDeductions,
  setQuestionsAnswered,
  setHomePriorDepreciation,
  setCarPriorDepreciation,
  setQueryResults
} from '@app/src/taxflow/shared/actions/sharedActions';
import { DEFAULT_COLLECTION_ID } from '@app/src/taxflow/shared/constants/sharedConstants';
import {
  currentCollectionIdSelector,
  deductionsSelector,
  queryResultsSelector
} from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { getQueryResultByEndpointAttribute } from '@app/src/taxflow/shared/utils/sharedUtils';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';
import { notify } from '@app/src/utils/snackbarUtils';

const baseUrl = serverUrl();

// Gets a value by endpoint attribute and converts it to a number, coalescing to 0
const getNumericValue = ({ queryResults, collectionId, collectionType, slug }) =>
  Number(
    _.get(getQueryResultByEndpointAttribute({ queryResults, collectionType, collectionId, slug }), [
      'answer',
      'value'
    ]) || 0
  );

const getHomePriorDepreciation = (queryResults, currentCollectionId) => async (dispatch) => {
  const totalHomeSize = getNumericValue({
    queryResults,
    collectionId: currentCollectionId,
    collectionType: COLLECTION_TYPE__HOME,
    slug: HOME_ENDPOINT_ATTRIBUTES.OFFICE_SIZE_TOTAL
  });

  const workSpaceSize = getNumericValue({
    queryResults,
    collectionId: currentCollectionId,
    collectionType: COLLECTION_TYPE__HOME,
    slug: HOME_ENDPOINT_ATTRIBUTES.OFFICE_SIZE_WORK_SPACE
  });

  const businessPercent = (workSpaceSize / totalHomeSize) * 100;

  const homePurchasePrice = getNumericValue({
    queryResults,
    collectionId: currentCollectionId,
    collectionType: COLLECTION_TYPE__HOME,
    slug: HOME_ENDPOINT_ATTRIBUTES.VALUE_PURCHASE_PRICE
  });

  const homeImprovements = getNumericValue({
    queryResults,
    collectionId: currentCollectionId,
    collectionType: COLLECTION_TYPE__HOME,
    slug: HOME_ENDPOINT_ATTRIBUTES.VALUE_IMPROVEMENTS
  });

  const valueOfLand = getNumericValue({
    queryResults,
    collectionId: currentCollectionId,
    collectionType: COLLECTION_TYPE__HOME,
    slug: HOME_ENDPOINT_ATTRIBUTES.VALUE_LAND
  });

  const cost = homePurchasePrice + homeImprovements - valueOfLand;

  const yearsDepreciation = getNumericValue({
    queryResults,
    collectionId: currentCollectionId,
    collectionType: COLLECTION_TYPE__HOME,
    slug: HOME_ENDPOINT_ATTRIBUTES.YEARS_DEPRECIATION
  });

  const res = await axios.post(`${baseUrl}api/taxes/home-depreciation-calc`, {
    yearsDepreciation,
    businessPercent,
    cost
  });

  const { priorDepreciation } = res.data.data;
  dispatch(setHomePriorDepreciation(priorDepreciation));
};

const getCogsSummary = () => async (dispatch) => {
  const res = await axios.get(`${baseUrl}api/tax-validation/cogs-qualified`);
  const summary = _.get(res, ['data', 'data']);
  dispatch(setCogsSummary(summary));
};

export const getDeductions = () => async (dispatch) => {
  const res = await axios.get(`${baseUrl}api/taxes/deductions`);
  const data = _.get(res, ['data', 'data']);
  dispatch(setDeductions(data));
};

export const getNextCollectionId = async ({ collectionType }) => {
  const res = await axios.get(`${baseUrl}api/taxes/get-next-collection-id`, { params: { coll_type: collectionType } });
  const nextCollectionId = _.get(res, ['data', 'data', 'coll_id']);
  return nextCollectionId;
};

const fetchAddMoreItems =
  ({ sectionSlug }) =>
  async (dispatch) => {
    const res = await axios.get(`${baseUrl}api/taxes/add-more-items`, { params: { sectionSlug } });
    const addMoreItems = _.get(res, ['data', 'data', 'addMoreItems']);
    dispatch(setAddMoreItems(addMoreItems));
  };

export const removeAboutYouItem = (collectionType) => async () => {
  await axios.post(`${baseUrl}api/taxes/remove-about-you-item`, {
    aboutYouItem: collectionType
  });
};

export const getQueryResults =
  ({ currentQuestion }) =>
  async (dispatch, getState) => {
    try {
      const allQuestions = allDerivedQuestionsSelector(getState());
      const currentCollectionId = currentCollectionIdSelector(getState());
      const allCollectionTypes = allCollectionTypesSelector(getState());

      const queries = [
        ...getQuestionQueries({
          question: currentQuestion,
          collectionId: currentQuestion.slug === SLUG__INCOME_INVEST_INFO ? null : currentCollectionId,
          allCollectionTypes
        }),
        ...navigationQueriesSelector(getState()),
        ...navigationStartedQueriesSelector(getState()),
        ...navigationDoneQueriesSelector(getState())
      ];

      if (currentQuestion.slug === SLUG__INCOME_INVEST_INFO) {
        queries.push(
          {
            coll_type: COLLECTION_TYPE__INCOME_INVEST,
            coll_id: currentQuestion.slug === SLUG__INCOME_INVEST_INFO ? currentCollectionId : null,
            slug: SLUG__INCOME_INVEST_UNIFICATION
          },
          {
            coll_type: COLLECTION_TYPE__INCOME_INVEST,
            coll_id: null,
            slug: ENDPOINT_ATTRIBUTE__INCOME_INVEST_STARTED
          }
        );
      } else if (currentQuestion.slug === CAR_SLUGS.MILEAGE_SPECIFIC) {
        queries.push({ coll_type: COLLECTION_TYPE__CAR, coll_id: '0' });
      }

      let queryResults = [];
      const res = await axios.post(`${baseUrl}api/taxes/user-tax-data/batch-get`, {
        queries
      });
      const items = _.get(res, ['data', 'data'], []);
      queryResults = getQuestionQueryResults({ allQuestions, items });
      dispatch(setQueryResults(queryResults));
    } catch (e) {
      notify('Something went wrong. Please try again.');
      defaultCaptureException(e);
    }
  };

export const getCurrentQuestionData =
  ({ currentQuestion }) =>
  async (dispatch) => {
    await Promise.all([
      dispatch(getCampaign()),
      dispatch(requireWork()),
      dispatch(requireAccountDetails()),
      dispatch(getFeatures()),
      dispatch(getWorkInfoLazy()),
      ...(currentQuestion.question_type === CATEGORY_TYPE_ADD_MORE
        ? [dispatch(fetchAddMoreItems({ sectionSlug: _.get(currentQuestion, ['question_meta', 'sectionSlug']) }))]
        : []),
      ...(currentQuestion.slug === SLUG__CREDIT_STANDARD_DEDUCTION ||
      currentQuestion.slug === SLUG__CREDIT_STANDARD_RESULT ||
      currentQuestion.slug === SLUG__CREDIT_HOMEOWNER_DETAIL ||
      currentQuestion.slug === SLUG__CREDIT_CHARITY_AMOUNT ||
      currentQuestion.slug === SLUG__CREDIT_STANDARD_ITEMIZED_SUCCESS
        ? [dispatch(getDeductions())]
        : []),
      ...(currentQuestion.slug === SLUG__SUBMIT_DEBIT ? [dispatch(getBankLinks())] : []),
      ...(currentQuestion.slug === SLUG__FIND_WRITE_OFFS ? [dispatch(getExpenses()), dispatch(getCategories())] : []),
      ...(currentQuestion.slug === SLUG__SUBMIT_CONFIRMATION ? [dispatch(getExpenses())] : []),
      ...(currentQuestion.question_type === CATEGORY_TYPE_SUMMARY ? [dispatch(getCollectionTypeItems())] : []),
      ...(currentQuestion.slug === SLUG__CREDIT_COGS_NEW_INVENTORY ||
      currentQuestion.slug === SLUG__CREDIT_COGS_START_INVENTORY ||
      currentQuestion.slug === SLUG__CREDIT_COGS_END_INVENTORY
        ? [dispatch(getCogsSummary())]
        : []),
      ...(currentQuestion.slug === CAR_SLUGS.NAV_START ? [dispatch(getCarPills())] : []),
      ...(currentQuestion.slug === CAR_SLUGS.MILEAGE_ESTIMATION ? [dispatch(getExpenses())] : []),
      ...(currentQuestion.slug === HOME_SLUGS.NAV_START ? [dispatch(getHomePills())] : []),
      ...([HOME_SLUGS.PRESELECT_ADDRESS, HOME_SLUGS.ADDRESS].includes(currentQuestion.slug)
        ? [dispatch(getHomeAddressPreselectOptions())]
        : []),
      ...([CAR_SLUGS.MILEAGE_ESTIMATION, HOME_SLUGS.EXPENSES_TOTAL].includes(currentQuestion.slug)
        ? [dispatch(getExpenses())]
        : [])
    ]);
  };

export const getQuestionsAnswered = () => async (dispatch) => {
  const res = await axios.get(`${baseUrl}api/taxes/user-tax-data/questions-answered`);

  dispatch(setQuestionsAnswered(_.get(res, ['data', 'data', 'fields'], [])));
};

export const updateItemizedDeduction = () => async (dispatch, getState) => {
  const allQuestions = allQuestionsSelector(getState());

  await dispatch(getDeductions());

  const deductions = deductionsSelector(getState());

  if (!deductions.switchToItemized) {
    return;
  }

  const creditStandardDeduction = getQuestionById({
    allQuestions,
    slug: 'credit-standard-deduction',
    coll_id: DEFAULT_COLLECTION_ID
  });

  // Answer standard deduction with standard
  const exitBounce = getQuestionById({
    allQuestions,
    slug: SLUG__CREDIT_STANDARD_EXIT_BOUNCE
  });

  await dispatch(
    updateTaxData({
      taxData: [
        ...[getStartedUpdates, getQuestionUpdates, getDoneUpdates].flatMap((fn) =>
          fn({
            question: creditStandardDeduction,
            answer: { value: 'itemized' },
            collectionId: DEFAULT_COLLECTION_ID
          })
        ),
        {
          coll_type: exitBounce.collectionType,
          coll_id: DEFAULT_COLLECTION_ID,
          slug: exitBounce.endpoint_attr,
          value: '1'
        }
      ],
      generateSharedCollectionId: false
    })
  );
};

export const dispatchHomeOfficeUpdates = async ({ dispatch, slug, queryResults, collectionId }) => {
  if (slug === HOME_SLUGS.PRIOR_DEPRECIATION) {
    await dispatch(getHomePriorDepreciation(queryResults, collectionId));
  }
};

export const getQuestionnaireQuestionAsQuestion =
  ({ questionnaireQuestion }) =>
  async (dispatch, getState) => {
    const { type, coll_type, coll_id, slug: questionnaireQuestionSlug } = questionnaireQuestion;
    await dispatch(setCurrentCollectionId(coll_id || DEFAULT_COLLECTION_ID));
    const maybeMatchingUpload = _.find(flattenedUploadAttemptsSelector(getState()), {
      collectionType: coll_type,
      collectionId: _.toNumber(coll_id),
      status: 'prefilled'
    });
    const bulkUploadItems = bulkUploadItemsSelector(getState());
    // if type is form review instead of question, display form details screen
    const slug =
      type === 'form-review' && maybeMatchingUpload && coll_type
        ? _.chain(bulkUploadItems)
            .find({ collectionType: coll_type, subType: maybeMatchingUpload.subType })
            .get('formInputQuestionSlug')
            .defaultTo(questionnaireQuestionSlug)
            .value()
        : questionnaireQuestionSlug;

    const allQuestions = allDerivedQuestionsSelector(getState());
    let clarifyingQuestionContent = getQuestionById({ allQuestions, slug });
    if (type === 'form-review') {
      clarifyingQuestionContent = {
        ...clarifyingQuestionContent,
        title: '',
        summary: ''
      };
    }

    await dispatch(getQueryResults({ currentQuestion: clarifyingQuestionContent }));
    const queryResults = queryResultsSelector(getState());
    await dispatchHomeOfficeUpdates({ dispatch, slug, queryResults, collectionId: coll_id });

    if (slug === SLUG__INCOME_FREELANCE_JOB) {
      const businessCodeQuery = queryResults.find(
        (result) =>
          result.coll_type === COLLECTION_TYPE__INCOME_FREELANCE &&
          result.slug === SLUG__INCOME_FREELANCE_BUSINESS_CODE &&
          result.coll_id === coll_id
      );
      const businessCodeValue = _.get(businessCodeQuery, ['answer', 'value']);
      await dispatch(setBusinessCode(businessCodeValue));
    } else if (slug === 'bulk-upload-questions-state-info') {
      const currentState = queryResults.find((queryResult) => {
        return queryResult.slug === SLUG__STATE_RETURN && queryResult.coll_id === '1';
      });
      await dispatch(setCurrentTaxState(_.get(currentState, ['answer', 'value'])));
    } else {
      await dispatch(setBusinessCode(null));
    }

    if (slug === CAR_SLUGS.NAV_START) {
      await dispatch(getCarPills());
    } else if (slug === HOME_SLUGS.NAV_START) {
      await dispatch(getHomePills());
    } else if ([HOME_SLUGS.PRESELECT_ADDRESS, HOME_SLUGS.ADDRESS].includes(slug)) {
      await dispatch(getHomeAddressPreselectOptions());
    } else if (slug === CAR_SLUGS.MILEAGE_ESTIMATION) {
      await dispatch(getExpenses());
    }

    return clarifyingQuestionContent;
  };

export const resetCurrentAnswer =
  ({ questionWithNewMeta }) =>
  async (dispatch, getState) => {
    const currentCollectionId = currentCollectionIdSelector(getState());
    const queryResults = queryResultsSelector(getState());

    if (questionWithNewMeta.slug === CAR_SLUGS.DEPRECIATION_AMOUNT_ESTIMATE) {
      await dispatch(getCarPriorDepreciation(queryResults, currentCollectionId));
    }

    if (questionWithNewMeta.slug === SLUG__INCOME_INVEST_INFO) {
      const unificationRecord = queryResults.find(
        (result) => result.slug === SLUG__INCOME_INVEST_UNIFICATION && result.coll_id === currentCollectionId
      );
      let collectionIds;
      if (unificationRecord) {
        collectionIds = _.get(unificationRecord, ['answer', 'value'], [currentCollectionId]);
      } else {
        collectionIds = [currentCollectionId];
      }

      const newValue = questionWithNewMeta.sub_question.reduce((result, subQuestion) => {
        collectionIds.forEach((collId) => {
          const queryResult = queryResults.find(
            (qr) =>
              qr.coll_type === subQuestion.collectionType &&
              collId === Number(qr.coll_id) &&
              qr.slug === subQuestion.endpoint_attr
          );

          const defaultAnswer = deserializeQuestionAnswer({ question: subQuestion, value: null });
          result = {
            ...result,
            [collId]: {
              ..._.get(result, collId, {}),
              [subQuestion.slug]: _.get(queryResult, 'answer', defaultAnswer)
            }
          };
        });

        return result;
      }, {});
      const currentAnswer = { value: newValue };
      dispatch(setCurrentAnswer(currentAnswer));
    } else if (questionWithNewMeta.slug === CAR_SLUGS.MILEAGE_SPECIFIC) {
      const carQueryResults = queryResults.filter((item) => item.coll_type === COLLECTION_TYPE__CAR);
      const carCollectionIds = _.uniq(carQueryResults.map((r) => r.coll_id).filter((id) => Number(id) > 0));
      const jobs = getJobData({ queryResults });

      const newValue = questionWithNewMeta.sub_question[0].sub_question.reduce((result, subQuestion) => {
        let res = result;
        for (const carCollectionId of carCollectionIds) {
          const defaultAnswer = deserializeQuestionAnswer({ question: subQuestion, value: null });
          if (subQuestion.slug !== CAR_SLUGS.MILEAGE_SPECIFIC_BUSINESS_MILEAGE) {
            const slug = `${subQuestion.endpoint_attr}-${carCollectionId}`;
            const savedAnswer = carQueryResults.find(
              (qr) => qr.coll_type === subQuestion.collectionType && qr.slug === slug
            );

            res[slug] = _.get(savedAnswer, 'answer', defaultAnswer);
          } else {
            const slugs = jobs.map(
              (job) => `${subQuestion.endpoint_attr}-${job.who}-${job.jobName.replaceAll('-', '')}-${carCollectionId}`
            );
            const jobLevelResults = carQueryResults.filter(
              (qr) => qr.coll_type === subQuestion.collectionType && slugs.includes(qr.slug)
            );
            for (const slug of slugs) {
              const savedAnswer = jobLevelResults.find((r) => r.slug === slug);
              res[slug] = _.get(savedAnswer, 'answer', defaultAnswer);
            }
          }
        }
        return res;
      }, {});
      const currentAnswer = { value: newValue };
      dispatch(setCurrentAnswer(currentAnswer));
    } else if (questionWithNewMeta.question_type === CATEGORY_TYPE_TAXFLOW_FORM) {
      const newValue = questionWithNewMeta.sub_question.reduce((result, subQuestion) => {
        const queryResult = getQueryResultByEndpointAttribute({
          queryResults,
          collectionType: subQuestion.collectionType,
          collectionId: currentCollectionId,
          slug: subQuestion.endpoint_attr
        });
        const savedDefaultAnswer = savedDefaultAnswerSelector(getState(), { question: subQuestion });
        const defaultAnswer = deserializeQuestionAnswer({ question: subQuestion, value: null });
        return {
          ...result,
          [subQuestion.slug]: _.get(
            queryResult,
            ['answer'],
            !_.isNil(savedDefaultAnswer) ? savedDefaultAnswer : defaultAnswer
          )
        };
      }, {});

      const currentAnswer = { value: newValue };
      dispatch(setCurrentAnswer(currentAnswer));
    } else {
      const queryResult = queryResults.find(
        (queryResult) =>
          queryResult.slug === questionWithNewMeta.endpoint_attr && queryResult.coll_id === currentCollectionId
      );
      const savedDefaultAnswer = savedDefaultAnswerSelector(getState(), { question: questionWithNewMeta });
      const defaultAnswer = deserializeQuestionAnswer({
        question: questionWithNewMeta,
        value: null
      });

      const currentAnswer = _.get(
        queryResult,
        ['answer'],
        !_.isNil(savedDefaultAnswer) ? savedDefaultAnswer : defaultAnswer
      );
      dispatch(setCurrentAnswer(currentAnswer));
    }
  };

export const getCarPriorDepreciation = (queryResults, currentCollectionId) => async (dispatch) => {
  const queryResultCarCost = getQueryResultByEndpointAttribute({
    queryResults,
    collectionType: COLLECTION_TYPE__CAR,
    collectionId: currentCollectionId,
    slug: CAR_ENDPOINT_ATTRIBUTES.COST
  });

  const queryResultCarYears = getQueryResultByEndpointAttribute({
    queryResults,
    collectionType: COLLECTION_TYPE__CAR,
    collectionId: currentCollectionId,
    slug: CAR_ENDPOINT_ATTRIBUTES.YEARS_DEPRECIATION
  });

  const cost = _.get(queryResultCarCost, ['answer', 'value']);
  const yearsDepreciation = _.get(queryResultCarYears, ['answer', 'value']);

  const res = await axios.post(`${baseUrl}api/taxes/car-depreciation-calc`, {
    yearsDepreciation,
    cost
  });
  const { priorDepreciation } = res.data.data;
  dispatch(setCarPriorDepreciation(priorDepreciation));
};
